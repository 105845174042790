// Add a listener for the page being loaded
window.addEventListener( 'load', function( event ) {

  // Get all children of the main element
  let mainChildren = document.querySelector( '.main' ).children;

  // Loop through the children in reverse order
  for ( let i = mainChildren.length - 1; i >= 0; i-- ) {

    // If this block doesn't have a background
    if ( ! mainChildren[i].className.includes( 'block-height-large' ) ) {

      // Add the block-background class
      mainChildren[i].classList.add( 'block-content-last' );

      // Break out of the for loop
      break;

    }
  }

} );
