// Stop any initial scroll to a hash
window.scrollTo( 0, 0 );

// Function to scroll to a specific heading
function scrollToHash( linkHref, behavior = 'smooth' ) {

  // Get the heading we should scroll to
  let elementToScrollTo = document.querySelector( linkHref );

  // Set a variable for the eventual scroll position
  let scrollToPosition = 0

  // Add the vertical position of the heading
  scrollToPosition += window.pageYOffset + elementToScrollTo.getBoundingClientRect().top;

  // Remove the height of the desktop header
  if ( desktopHeader = document.querySelector( '#header-desktop' ) ) {
    scrollToPosition -= desktopHeader.getBoundingClientRect().height;
  }

  // Remove the height of the mobile header
  if ( mobileHeader = document.querySelector( '#header-mobile' ) ) {
    scrollToPosition -= mobileHeader.getBoundingClientRect().height;
  }

  // Remove the height of the admin bar
  if ( adminBar = document.querySelector( '#wpadminbar' ) ) {
    scrollToPosition -= adminBar.getBoundingClientRect().height;
  }

  // Remove a bit more for spacing
  scrollToPosition -= 30;

  // Scroll to that point
  window.scrollTo( {
    behavior: behavior,
    left: 0,
    top: scrollToPosition,
  } );

  // Keyboard focus on the scrolled-to element
  elementToScrollTo.focus();
}

// Add a listener for the page being loaded
window.addEventListener( 'load', function( event ) {

  // Loop through the headings and accordions
  document.querySelectorAll( '.main > h2, .main > h3, h2.section-heading' ).forEach( function( element ) {

    // Get the element text, stripping tags
    let element_text = element.innerHTML.replace(/<\/?[^>]+(>|$)/g, '');

    // Construct the ID by:
    //  replacing everything except alphanumeric characters, dashes and spaces,
    //  converting to lower case,
    //  and replacing spaces with dashes.
    let element_id = encodeURIComponent( element_text.replace( /[^0-9A-Z\-\s]+/gi, '' ).toLowerCase().replaceAll( ' ', '-' ) );

    // Set the ID on the element
    element.setAttribute( 'id', element_id );

    // Set up a variable for the link
    let copyLink = document.createElement( 'a' );

    // Set the class
    copyLink.setAttribute( 'class', 'component-in-page-navigation-link' );

    // Set the href
    copyLink.setAttribute( 'href', '#' + element_id );

    // Set the aria-label
    copyLink.setAttribute( 'aria-label', 'Link to this part of the page' );

    // Add the icon
    copyLink.innerHTML = '<svg class="icon link" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>';

    // Append the link
    element.appendChild( copyLink );

  } );

  // If there's a hash
  if ( window.location.hash ) {

    // Scroll to it
    scrollToHash( window.location.hash );

  }

  // Loop through any links that start with a hash
  document.querySelectorAll( 'a[ href^="#" ]' ).forEach( function( link ) {

    // Add an event listener for a click
    link.addEventListener( 'click', function( event ) {

      // Stop the link being followed so we can control the scroll
      event.preventDefault();

      // Get the href of the clicked link
      let linkHref = event.currentTarget.getAttribute( 'href' );

      // Scroll to that point
      scrollToHash( linkHref );

      // Add the URL hash
      window.history.pushState( {}, '', window.location.origin + window.location.pathname + linkHref );

    } );

  } );

} );
