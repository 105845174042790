// Try to get any mini-listing patch links
const patchLinks = document.querySelectorAll( '.patch-link' );

// If there are patch links
if ( patchLinks.length > 0 ) {

  // Loop through them
  patchLinks.forEach( function( patchLink ) {

    // Add the clickable class
    patchLink.classList.add( 'clickable' );

    // Add a click event listener
    patchLink.addEventListener( 'click', function( event ) {

      // If the meta key (command key on Mac, Windows key on, well, Windows) is not being pressed
      if ( ! event.metaKey ) {

        // Change the URL to the patch's link
        document.location.href = patchLink.querySelector( 'a' ).getAttribute( 'href' );

      // Otherwise the meta key is being pressed
      } else {

        // Open the patch's link in a new tab
        window.open( patchLink.querySelector( 'a' ).getAttribute( 'href' ), '_blank' );

      }

    } );
  } );
}
