/* global showOverlay, hideOverlay */

// Set constants for the DOM nodes
const mobileMenuToggle = document.querySelector( '#header-mobile-menu-toggle' );
const mobileMenuWrapper = document.querySelector( '#mobile-menu-wrapper' );

// Only try to do this if the mobile menu toggle exists
if ( mobileMenuToggle ) {

	// Enable menu toggle
	mobileMenuToggle.addEventListener( 'click', function ( event ) {
		// If the menu isn't open yet
		if ( mobileMenuToggle.getAttribute( 'aria-expanded' ) === 'false' ) {
			// Show the overlay
			showOverlay( 'mobile-overlay', 50 );

			// Show the mobile menu
			mobileMenuWrapper.style.display = 'block';

			// Set the aria-expanded attribute to true
			// The menu visibility is controlled by this attribute in CSS
			mobileMenuToggle.setAttribute( 'aria-expanded', 'true' );

			// Update the aria label
			mobileMenuToggle.setAttribute(
				'aria-label',
				mobileMenuToggle.getAttribute( 'aria-label' ).replace( 'Show', 'Hide' )
			);

			// Otherwise the menu is already open
		} else {
			// Hide the mobile menu
			mobileMenuWrapper.style.display = 'none';

			// Set the aria-expanded attribute to true
			// The menu visibility is controlled by this attribute in CSS
			mobileMenuToggle.setAttribute( 'aria-expanded', 'false' );

			// Update the aria label
			mobileMenuToggle.setAttribute(
				'aria-label',
				mobileMenuToggle.getAttribute( 'aria-label' ).replace( 'Hide', 'Show' )
			);

			// Hide the overlay
			hideOverlay( 'mobile-overlay' );
		}
	} );

	// Loop through all mobile menu links with children
	document
		.querySelectorAll( '.mobile-menu-link.has-children' )
		.forEach( function ( mobileMenuItemLink ) {
			// Event listener - when this menu item link is clicked
			mobileMenuItemLink.addEventListener( 'click', function ( event ) {
				// Stop the link being followed and any propagation
				event.preventDefault();

				// Store the clicked element as a variable
				let clickedMobileMenuItemLink = event.target;

				// If the submenu isn't open yet
				if (
					clickedMobileMenuItemLink.getAttribute( 'aria-expanded' ) === 'false'
				) {
					// Set the aria-expanded attribute to true
					// The menu visibility is controlled by this attribute in CSS
					clickedMobileMenuItemLink.setAttribute( 'aria-expanded', 'true' );

					// Update the aria label
					clickedMobileMenuItemLink.setAttribute(
						'aria-label',
						clickedMobileMenuItemLink
							.getAttribute( 'aria-label' )
							.replace( 'Show', 'Hide' )
					);

					// Otherwise the submenu is already open
				} else {
					// Set the aria-expanded attribute to true
					// The menu visibility is controlled by this attribute in CSS
					clickedMobileMenuItemLink.setAttribute( 'aria-expanded', 'false' );

					// Update the aria label
					clickedMobileMenuItemLink.setAttribute(
						'aria-label',
						clickedMobileMenuItemLink
							.getAttribute( 'aria-label' )
							.replace( 'Hide', 'Show' )
					);
				}
			} );
		} );
}
